import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Container, Typography, Button} from '@material-ui/core'
import styles from '../styles'
import Auth from '@aws-amplify/auth'

const useStyles = makeStyles(theme => ({
  ...styles(theme)
}))

let LogOutOptions = () => {
  const classes = useStyles()
  // const theme = useTheme();

  return (
    <React.Fragment>
      <Container className={classes.lightContainer}>
        <Typography variant="h5" gutterBottom={true}>Log Out</Typography>
        <Button variant="contained" color="primary" onClick={() => Auth.signOut()}>Logout</Button>
      </Container>
    </React.Fragment>
  )
}

export default LogOutOptions