import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Container, CircularProgress} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    marginBottom: "50px"
  }
}))

let Spinner = () => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <CircularProgress color="primary" />
    </Container>
  )
}

export default Spinner