import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LinkLink from '../components/LinkLink'
import {Container, Typography} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../components/AppStore'
import ChangeSubscriptionView from './Subscriptions/ChangeSubscriptionView'
import SubscribeView from './Subscriptions/SubscribeView'
import CancelSubscriptionView from './Subscriptions/CancelSubscriptionView'
import SubscriptionInfo from './Subscriptions/SubscriptionInfo'
import UpdatePaymentMethodView from './Subscriptions/UpdatePaymentMethodView'
import styles from '../styles'
import Spacer from '../components/Spacer'

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  subscriptionForm: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2)
  },
  subscriptionFormPaper: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: "0"
    }
  }
}))

let SubscriptionSettings = observer((props) => {
  const classes = useStyles()
  // const theme = useTheme();
  const appStore = useAppStore()

  return (
    <React.Fragment>
      <Container className={classes.lightContainer}>
        {(appStore.isSubscribed && !appStore.cancelAtPeriodEnd) ?
            <Typography variant="h5" gutterBottom={true}>THANK YOU for Sponsoring!</Typography>
            : <Typography variant="h5" gutterBottom={true}>Become a Sponsor!</Typography>}
        <div><Typography variant="body1" gutterBottom={true}>Jellygon sponsors can play Starsteaders as it is being 
          developed (you don't need to sponsor to play right now, but soon you will).  Pay what you want and come 
          back here to manage your sponsorship at any time.  If you have any questions or concerns, 
          email <a className={classes.emailLink} href="mailto:support@jellygon.com">support@jellygon.com</a>.</Typography></div>
        <div style={{margin: "20px 0"}}>
          <Typography gutterBottom={true} variant="h6" color="error">
            <b>Important - read this first:</b>&nbsp;&nbsp;
            <LinkLink color="secondary" href="/whatisthis" style={{textDecoration: "underline"}}>What is this?</LinkLink>
          </Typography>
        </div>
      </Container>
      <Container style={{padding: 0}} maxWidth="sm">
        {appStore.isSubscribed ? <div><SubscriptionInfo /><Spacer /></div> : null}
        {appStore.isSubscribed ? <div><ChangeSubscriptionView /><Spacer /></div> : <div><SubscribeView /><Spacer /></div>}
        {appStore.isSubscribed ? <div><UpdatePaymentMethodView /><Spacer /></div> : null}
        {(appStore.isSubscribed && !appStore.cancelAtPeriodEnd) ? <div><CancelSubscriptionView /><Spacer /></div> : null}
      </Container>
    </React.Fragment>
  )
})

export default SubscriptionSettings