import * as React from 'react';
import { SignUp } from 'aws-amplify-react/lib/Auth'
import { I18n } from '@aws-amplify/core';
import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  FormField,
  Input,
  InputLabel,
  Button,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from 'aws-amplify-react/lib/Amplify-UI/Amplify-UI-Components-React';
import { Checkbox } from '@material-ui/core'
import OutboundLink from '../OutboundLink'

export default class JellygonSignUp extends SignUp {
  componentWillMount() {
    this.setState({ acceptedTOS: false, newsletter: false })
  }

  validate() {
    let fields = super.validate()
    if (fields.length > 0)
      return fields;
    if (!this.state.acceptedTOS)
      return ['Terms of Service'];
    // email and username are case-sensitive in cognito for some baffling reason
    this.inputs['username'] = this.inputs['username'].toLowerCase();
    this.inputs['email'] = this.inputs['email'].toLowerCase();
    // hack in the TOS/Privacy Policy version and newsletter value which will end up in cognito
    this.inputs['tos'] = 'TOS_090519'
    this.inputs['newsletter'] = this.state.newsletter ? 'true' : 'false'
    return [];
  }

  // copied from aws amplify's SignUp and modified
  showComponent(theme) {
    // const { hide } = this.props;
    // if (hide && hide.includes(SignUp)) { return null; }
    if (this.checkCustomSignUpFields()) {
        this.signUpFields = this.props.signUpConfig.signUpFields;
    }
    this.sortFields();
    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>{I18n.get(this.header)}</SectionHeader>
        <SectionBody theme={theme}>
          {
            this.signUpFields.map((field) => {
              if (field.key === 'tos' || field.key === 'newsletter')
                return null;
              return field.key !== 'phone_number' ? (
                <FormField theme={theme} key={field.key}>
                {
                    field.required ? 
                    <InputLabel theme={theme}>{I18n.get(field.label)} *</InputLabel> :
                    <InputLabel theme={theme}>{I18n.get(field.label)}</InputLabel>
                }
                    <Input
                        autoFocus={
                            this.signUpFields.findIndex((f) => {
                                return f.key === field.key
                            }) === 0 ? true : false
                        }
                        placeholder={I18n.get(field.placeholder)}
                        theme={theme}
                        type={field.type}
                        name={field.key}
                        key={field.key}
                        onChange={this.handleInputChange}
                    />
                </FormField>
              ) : (
                null // don't feel like importing PhoneField
                // <PhoneField 
                //     theme={theme} 
                //     required={field.required}
                //     defaultDialCode={this.getDefaultDialCode()}
                //     label={field.label}
                //     placeholder={field.placeholder}
                //     onChangeText={this.onPhoneNumberChanged}
                //     key="phone_number"
                // />
              );
            })
          }
          <div style={{display: "flex", alignItems: "center"}}>
            <InputLabel theme={theme} style={{marginBottom: 0}}>I agree to 
                the <OutboundLink href="/terms">Terms of Service</OutboundLink> and <OutboundLink
                href="/privacy">Privacy Policy</OutboundLink> *</InputLabel>
            <Checkbox value="acceptedTOS" onChange={(e) => this.setState({ acceptedTOS: e.target.checked })} />
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <InputLabel theme={theme} style={{marginBottom: 0}}>Can I email you news and progress updates?</InputLabel>
            <Checkbox value="newsletter" onChange={(e) => this.setState({ newsletter: e.target.checked })} />
          </div>
        </SectionBody>
        <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
                <Button onClick={this.signUp} theme={theme}>
                    {I18n.get('Create Account')}
                </Button>
            </SectionFooterPrimaryContent>
            <SectionFooterSecondaryContent theme={theme}>
                {I18n.get('Have an account? ')}
                <Link theme={theme} onClick={() => this.changeState('signIn')}>
                    {I18n.get('Sign in')}
                </Link>
            </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    );    
  }
}