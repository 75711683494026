import React, { useEffect } from 'react'
import { Authenticator, SignUp } from 'aws-amplify-react/lib/Auth'
import { AmplifyTheme } from 'aws-amplify-react/lib/AmplifyTheme'
import {Container, Typography} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import styles from '../styles'
import { useAppStore } from '../components/AppStore'
import { Elements } from 'react-stripe-elements'
import SubscriptionSettings from '../components/SubscriptionSettings'
import AccountOptions from '../components/AccountOptions'
import LogOutOptions from '../components/LogOutOptions'
import { observer } from 'mobx-react-lite'
import Spinner from '../components/Spinner'
// import { Hub } from '@aws-amplify/core'
import GenericBanner from '../components/GenericBanner'
import JellygonSignUp from '../components/Auth/JellygonSignUp';
import Footer from '../components/Footer'
import '@aws-amplify/ui/dist/style.css'

import { I18n } from '@aws-amplify/core'
I18n.putVocabulariesForLanguage('en', 
  { 
    'Confirmation Code': 'Verification Code (check your email)', // signup code
    'Code': 'Verification Code (check your email)', // forgot password code
    'Enter your username': 'Username or email address' // username placeholder
  }
)


const useStyles = makeStyles(theme => { return {
  ...styles(theme),
  authContent: {
    position: "relative",
    overflow: "auto", /* prevent children with margins from affecting this parent */
    padding: "0",
  }
}})

const LoggedInView = observer(() => {
  const appStore = useAppStore()
  const classes = useStyles()

  return (
    <Container className={classes.lightContainer} style={{padding: 0}}>
      {appStore.hasCustomerInfo 
          ? <React.Fragment>
              <AccountOptions />
              {/* <Elements>
                <SubscriptionSettings /> 
              </Elements> */}
              <LogOutOptions />
            </React.Fragment>
          : <React.Fragment>
              <div style={{marginTop: "25px", textAlign: "center"}}>
                <Typography variant="body1">Loading your account details...</Typography>
              </div>
              <Spinner />
            </React.Fragment>}
    </Container>
  )
})

const LoggedOutView = observer(() => {
  const classes = useStyles()
  const theme = useTheme();

  const authenticatorTheme = Object.assign({}, AmplifyTheme, { 
    toast: { backgroundColor: "#D8212F" },
    button: { backgroundColor: theme.palette.primary.main }
  });

  const signUpConfig = {
    hiddenDefaults: ["phone_number"],
    // these fields will be hidden and we'll use our own inputs, but amplify needs them here
    signUpFields: [{ label: '', key: 'tos', type: 'string', custom: true }, 
        { label: '', key: 'newsletter', type: 'string', custom: true }]
  }

  return (
    <Container maxWidth="md" className={classes.authContent} style={{padding: 0}}>
      <Authenticator authState="signUp" signUpConfig={signUpConfig} theme={authenticatorTheme} hide={[SignUp]}>
        <JellygonSignUp override="SignUp" signUpConfig={signUpConfig} /> 
      </Authenticator>
    </Container>
  )
})

const AccountPage = observer((props) => {
  const classes = useStyles()
  const appStore = useAppStore()

  useEffect(() => {
    if (appStore.user !== null && !appStore.hasCustomerInfo)
      appStore.refreshCustomerInfo()
  })

  // useEffect(() => {
  //   const showAlert = async authState => {
  //     console.log(authState)
  //   }
  //   Hub.listen('auth', showAlert)
  //   return () => Hub.remove('auth', showAlert)
  // }, [])

  return (
    <React.Fragment>
      <div className={classes.root}>
        <GenericBanner label="YOUR ACCOUNT" />
        <Container className={classes.lightContainer} maxWidth="md" style={{flexGrow: "1", padding: 0}}>
          {(appStore.user !== null) ? <LoggedInView /> : <LoggedOutView />}
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  )
})

export default AccountPage