import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => {
  return {
    buttonProgress: {
      color: theme.palette.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    }
  }
})

export default function FeedbackButton({loading, ...props}) {
  let classes = useStyles()

  return <Button {...props}>
    {props.children}
    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
  </Button>
}