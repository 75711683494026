import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Container, Typography, Button} from '@material-ui/core'
import { useLocalStore, observer } from 'mobx-react-lite'
import { useAppStore } from '../components/AppStore'
import styles from '../styles'
import FeedbackButton from '../components/FeedbackButton'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  ...styles(theme)
}))

let AccountOptions = observer((props) => {
  const classes = useStyles()
  // const theme = useTheme();
  const appStore = useAppStore()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const store = useLocalStore(() => {
    return {
      submitting: false
    }
  })
  
  const setNewsletter = async newsletter => {
    if (store.submitting)
      return;
    store.submitting = true
    try {
      await appStore.setNewsletter(newsletter)
      enqueueSnackbar(newsletter ? "You are subscribed to email updates." : "You have been unsubscribed from email updates.", 
          { variant: "success",
            action: key => <Button onClick={() => { closeSnackbar(key) }}>{'Dismiss'}</Button>});
      await appStore.refreshCustomerInfo()
    } catch (e) {
      enqueueSnackbar(e.message ? e.message : e.toString(), 
          { variant: "error",
            action: key => <Button onClick={() => { closeSnackbar(key) }}>{'Dismiss'}</Button>});
    } finally {
      store.submitting = false
    }
  }

  return (
    <React.Fragment>
      <Container className={classes.lightContainer}>
        <Typography variant="h5" gutterBottom={true}>Account Options</Typography>
        {appStore.newsletter
          ? <React.Fragment>
              <Typography variant="body1" gutterBottom={true}>You're signed up to receive news and progress updates by email.  Thanks!
              </Typography>
              <FeedbackButton variant="contained" color="primary" onClick={() => setNewsletter(false)}
                  disabled={store.submitting} loading={store.submitting}>
                  Unsubscribe me from email updates</FeedbackButton>
            </React.Fragment>
          : <React.Fragment>
              <Typography variant="body1" gutterBottom={true}>Can I email you news and progress updates?</Typography>
              <FeedbackButton variant="contained" color="primary" onClick={() => setNewsletter(true)} 
                  disabled={store.submitting} loading={store.submitting}>
                  Subscribe to email updates</FeedbackButton>
            </React.Fragment>
        }
      </Container>
    </React.Fragment>
  )
})

export default AccountOptions