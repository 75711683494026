import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {injectStripe} from 'react-stripe-elements'
import {CardElement} from 'react-stripe-elements';
import {Paper, Button, FormControl, Typography} from '@material-ui/core'
import stripeLogo from '../../images/powered_by_stripe.svg'
import { useLocalStore, observer } from 'mobx-react-lite'
import { useAppStore } from '../../components/AppStore'
import FeedbackButton from '../FeedbackButton'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  subscriptionForm: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2)
  },
  subscriptionFormPaper: {
    [theme.breakpoints.down('xs')]: {
      borderRadius: "0"
    }
  }
}))

let UpdatePaymentMethodView = observer((props) => {
  const classes = useStyles()
  const appStore = useAppStore()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const cardRef = useRef()

  const store = useLocalStore(() => {
    return {
      submitting: false
    }
  })
  
  const [state, setState] = React.useState({
  })
  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (store.submitting)
      return;
    if (!props.stripe)
      return;
    store.submitting = true
    try {
      // fixme - error handling
      let result = await props.stripe.createPaymentMethod('card')
      if (result.error)
        throw new Error(result.error.message)
      cardRef.current._element.clear()
      result = await appStore.setPaymentMethod(result.paymentMethod.id)
      enqueueSnackbar("Payment method updated.", 
          { variant: "success",
            action: key => <Button onClick={() => { closeSnackbar(key) }}>{'Dismiss'}</Button>});
      await appStore.refreshCustomerInfo()
    } catch (e) {
      enqueueSnackbar(e.message ? e.message : e.toString(), 
          { variant: "error",
            action: key => <Button onClick={() => { closeSnackbar(key) }}>{'Dismiss'}</Button>});
    } finally {
      store.submitting = false
    }
  }

  return (
    <Paper className={classes.subscriptionFormPaper}>
      <form className={classes.subscriptionForm} onSubmit={onSubmit}>
        <Typography variant="h5">Update your Payment Method</Typography>
        <FormControl>
          <label style={{borderBottom: "1px solid lightgrey"}}>
            Card details
            <CardElement style={{base: {fontSize: '18px'}}} onChange={handleChange} disabled={store.submitting} 
                ref={cardRef} />
          </label>
        </FormControl>
        <FeedbackButton type="submit" color="primary" mx="auto" size="large" variant="contained" 
              style={{marginTop:"15px"}} disabled={store.submitting} loading={store.submitting}>Submit</FeedbackButton>
        <Button href="https://stripe.com" target="_blank" rel="noopener noreferrer" variant="text" disableRipple={true}
            style={{alignSelf: "flex-end", marginTop: "15px", padding: "0"}}>
          <img alt="Stripe Logo" src={stripeLogo} />
        </Button>
      </form>
    </Paper>
  )
})

export default injectStripe(UpdatePaymentMethodView)