import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Paper, Typography} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useAppStore } from '../AppStore'

const useStyles = makeStyles(theme => ({
  subscriptionFormPaper: {
    padding: theme.spacing(3, 2),
    [theme.breakpoints.down('xs')]: {
      borderRadius: "0"
    }
  }
}))

let SubscriptionInfo = observer((props) => {
  const classes = useStyles()
  // const theme = useTheme();
  const appStore = useAppStore()

  let friendlyDate = appStore.nextInvoiceDate.toLocaleDateString()

  return (
    <Paper className={classes.subscriptionFormPaper}>
      <Typography variant="h5">Your Sponsorship</Typography>
      {appStore.cancelAtPeriodEnd
        ? <Typography>Your sponsorship has been cancelled.  Your account will remain active for the rest of this 
              billing period until {friendlyDate}.  Update your sponsorship below if 
              you want to re-enable it.</Typography>
        : <Typography>You are currently sponsoring for <b>${appStore.subscriptionDollarAmount} per 
              month</b>.  Thank you!</Typography>}
      <br />
      {!appStore.cancelAtPeriodEnd
          ? <Typography>Next billing date: <b>{friendlyDate}</b></Typography>
          : null}
      {appStore.hasPaymentMethod
          ? <Typography>Billing method: <b>Card ending in {appStore.last4}</b></Typography>
          : null}
      {appStore.isUnpaid
          ? <Typography style={{color: "red"}}><b>Your subscription is incomplete or unpaid.  Please update your 
                billing information below and we'll try again later.</b></Typography>
          : null}
    </Paper>
  )
})

export default SubscriptionInfo